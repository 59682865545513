import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { 
   Box, 
   Button,
   Center,
   Input,
   InputLeftElement,
   InputGroup,
   Spinner,
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionPanel,
   AccordionIcon,
   Select,
   useToast,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import { AiOutlineSearch } from "react-icons/ai"
import axios  from 'axios'
import Layout from '../components/layout.js';
import {
   Midasi2,
   Midasi3,
   RadioButtonsC4,
   TitleList1,
   CommaInput2,
   SwiperTitleList1,
   SwiperTitleList3
} from '../util/functions.js';

// import {InfluxDB, FluxTableMetaData} from '@influxdata/influxdb-client';



const IndexPage = (pageprops) => {
   const NewPostList = pageprops.data.allWpPost.nodes
      .slice(0,6);
   const NewCreaterPostList = pageprops.data.allWpPost.nodes
      .filter(node => node.categories.nodes[0].slug === "creater")
      .slice(0,6);
   const NewNewsPostList = pageprops.data.allWpPost.nodes
      .filter(node => node.categories.nodes[0].slug === "news")
      .slice(0,6);
   const NewNoticePostList = pageprops.data.allWpPost.nodes
      .filter(node => node.categories.nodes[0].slug === "notice")
      .slice(0,6);
   const NewFeaturePostList = pageprops.data.allWpPost.nodes
      .filter(node => node.categories.nodes[0].slug === "feature")
      .slice(0,6);
   const NewReportPostList = pageprops.data.allWpPost.nodes
      .filter(node => node.categories.nodes[0].slug === "report")
      .slice(0,6);


   const myCategoryWords = pageprops.data.wp.myCategoryWords.items;
   // console.log(myCategoryWords);
   const myStatueWords = pageprops.data.wp.myStatueWords.items;
   // console.log(myStatueWords);
   const myFiveRanks = pageprops.data.wp.myFiveRank.items;
   // console.log(myFiveRanks);

   const [channelKeyword,setChannelKeyword] = useState("");
   const [radioMyStatueWordVal, setRadioMyStatueWordVal] = useState("全て");
   const [radioMyCategoryWord, setRadioMyCategoryWord] = useState("全て");

   // const [MyStatueWord, setMyStatueWord] = useState("");
   // const [MyCategoryWord, setMyCategoryWord] = useState("");
   // const [five_ranks,setfive_ranks] = useState({
   //    ranks:{},
   //    finished:false
   // });

   const [ch_viewDownTarget,setch_viewDownTarget] = useState(0);
   const [ch_viewUpTarget,setch_viewUpTarget] = useState(0);
   const [ch_registDownTarget,setch_registDownTarget] = useState(0);
   const [ch_registUpTarget,setch_registUpTarget] = useState(0);

   const [videoKeyword,setVideoKeyword] = useState("");
   const YoutubeCategories = {
      catname:[
         "全て",
         "急上昇",
         "映画とアニメ",
         "自動車と乗り物",
         "音楽",
         "ペットと動物",
         "スポーツ",
         // "ショート ムービー",
         "旅行とイベント",
         "ゲーム",
         // "動画ブログ",
         "ブログ",
         "コメディー",
         "エンターテイメント",
         "ニュースと政治",
         "ハウツーとスタイル",
         "教育",
         "科学と技術",
         // "映画",
         // "アニメ",
         // "アクション/アドベンチャー",
         // "クラシック",
         // "コメディー",
         // "ドキュメンタリー",
         // "ドラマ",
         // "家族向け",
         // "海外",
         // "ホラー",
         // "SF/ファンタジー",
         // "サスペンス",
         // "短編",
         // "番組",
         "予告編",
         "非営利活動"
      ]
   };
   const [radioMyVideoCategoryWord, setradioMyVideoCategoryWord] = useState("全て");

   const [vi_viewDownTarget, setvi_viewDownTarget] = useState(0)
   const [vi_viewUpTarget, setvi_viewUpTarget] = useState(0)
   const [vi_likeDownTarget, setvi_likeDownTarget] = useState(0)
   const [vi_likeUpTarget, setvi_likeUpTarget] = useState(0)
   const [vi_commentDownTarget, setvi_commentDownTarget] = useState(0)
   const [vi_commentUpTarget, setvi_commentUpTarget] = useState(0)

   // const [SpecialWord, setSpecialWord] = useState("");

   const toast = useToast();
   const [reqestform, setreqestform] = useState({
      channelUrl : "",
      Statue : "",
      Category : "",
      isDisable : false
   });


   const updataRequestKeyword = (word,wordtype) => {
      var type="updataRequestKeyword";
      // let postdata = Object.assign({type:type}, {word:word});
      let postdata = {
         type:type, 
         word:word,
         wordtype:wordtype,
      };
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res);
         })
         .catch( (error) => {
            console.log(error);
      });
   }

   const updataChannelRequest = (param) => {
      setreqestform({
         ...reqestform,
         isDisable:true
      });
      var type="updataChannelRequest";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            toast({
               title: "リクエスト受付完了",
               description: "リクエストを受付ました。掲載開始までしばらくお待ちください。",
               status: "success",
               duration: 9000,
               isClosable: true,
               position: "top",
            });
            setreqestform({
               ...reqestform,
               isDisable:false
            });
         })
         .catch( (error) => {
            console.log(error);
            setreqestform({
               ...reqestform,
               isDisable:false
            });
      });
   }

   useEffect(() => {
      // let type="bigqueryTest";
      // let postdata = Object.assign({type:type});
      // axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
      //    .then( (res) => {
      //       console.log(res.data);
      //       // setSpecialWord(res.data);
      //    })
      //    .catch( (error) => {
      //       console.log(error);
      //       // setSpecialWord("");
      // });
      //Initialize the Client-----------------------------------
      // const {InfluxDB, flux} = require('@influxdata/influxdb-client')
      // const url = 'http://localhost:8086';
      // const token = 'qE83Rq0yQPGek6teUu745OkrOKW7jmInL5QrMq48-VIaXOagxPP3B8fvATAZsi7avaOlOSuMI0lRAKY9h9hnxg=='
      // const org = 'TeamEE'
      // const bucket = 'fdre818'
      // const client = new InfluxDB({url: url, token: token})

      // const url = 'http://localhost:8086';
      // const {InfluxDB} = require('@influxdata/influxdb-client-browser')
      // const {PingAPI} = require('@influxdata/influxdb-client-apis')
      // // const {PingAPI} = require('../packages/apis')
      // // const {url} = require('./env')
      // const timeout = 10 * 1000 // timeout for ping
      
      // console.log('*** PING STATUS ***')
      // const influxDB = new InfluxDB({url, timeout})
      // const pingAPI = new PingAPI(influxDB)
      
      // pingAPI
      //   .getPing()
      //   .then(() => {
      //     console.log('\nPing SUCCESS')
      //   })
      //   .catch(error => {
      //     console.error(error)
      //     console.log('\nFinished ERROR')
      //   })

   axios.get(
            "http://localhost:3000/api/objectdata", 
            { }, 
            {
               // headers: {
               //    'Authorization': 'Token ' + token,
               // }
            }
         ).then( (res) => {
            console.log("aaaa");
            console.log(res.data);
            // setSpecialWord(res.data);
         })
         .catch( (error) => {
            console.log("cccc");
            console.log(error);
            // setData("");
         });

         
   // const https = require('https');
   // const token = 'qE83Rq0yQPGek6teUu745OkrOKW7jmInL5QrMq48-VIaXOagxPP3B8fvATAZsi7avaOlOSuMI0lRAKY9h9hnxg=='
   // // function queryWithToken() {
   
   //   const options = {
   //     host: 'localhost:8086',
   //     path: "/api/v2",
   //     headers: {
   //       'Authorization': token,
   //       'Content-type': 'application/json'
   //     },
   //   };
   
   //   const request = https.get(options, (response) => {
   //     let rawData = '';
   //     response.on('data', () => {
   //       response.on('data', (chunk) => { rawData += chunk; });
   //     })
   //     response.on('end', () => {
   //       console.log(rawData);
   //     })
   //   });
   
   //   request.end();
   // }


      // let type="getSpecialWord";
      // let postdata = Object.assign({type:type});
      // axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
      //    .then( (res) => {
      //       // console.log(res.data);
      //       setSpecialWord(res.data);
      //    })
      //    .catch( (error) => {
      //       console.log(error);
      //       setSpecialWord("");
      // });

      // let type="get_five_rank";
      // let postdata = Object.assign({type:type});
      // axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
      // .then( (res) => {
      //    // console.log(res.data.channellists);
      //    setfive_ranks({
      //       ranks:res.data.channellists,
      //       finished:true,
      //    })
      // })
      // .catch( (error) => {
      //    console.log(error);
      //    setfive_ranks({
      //       ranks:{},
      //       finished:true,
      //    })
      // });

      // type="getMyStatueWord";
      // postdata = Object.assign({type:type});
      // axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
      //    .then( (res) => {
      //       // console.log(res.data);
      //       setMyStatueWord(res.data);
      //    })
      //    .catch( (error) => {
      //       console.log(error);
      //       setMyStatueWord("");
      // });

      // type="getMyCategoryWord";
      // postdata = Object.assign({type:type});
      // axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
      //    .then( (res) => {
      //       // console.log(res.data);
      //       setMyCategoryWord(res.data);
      //    })
      //    .catch( (error) => {
      //       console.log(error);
      //       setMyCategoryWord("");
      // });

      // type="getViThumbnails";
      // postdata = Object.assign({type:type},YoutubeCategories);
      // axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
      //    .then( (res) => {
      //       // console.log(res.data);
      //       setViThumbnails(res.data);
      //    })
      //    .catch( (error) => {
      //       // console.log(error);
      //       setViThumbnails("");
      // });
   }, []);

   // const channelIdRef = useRef(null);
   // const videoIdRef = useRef(null);
   const [channelIdRef,setchannelIdRef] = useState("");
   const [videoIdRef,setvideoIdRef] = useState("");
   return (
      <>
         <Layout uri={pageprops.uri}>

            <SwiperTitleList3 five_ranks={myFiveRanks} />
            {/* <SwiperTitleList2 five_ranks={five_ranks} /> */}
            {/*
            <Center mt={"1rem"}>
               <Button 
                  // w="60%"
                  rounded=""
                  as={GatsbyLink} 
                  to={"/channellist"} 
                  m={5} 
                  colorScheme="red"
                  onClick={() =>updataRequestKeyword(channelKeyword,"channel")}
                  // onClick={() =>console.log(ch_viewDownTarget.current.value.replace(/,/g, ''))}
                  // onClick={() =>console.log(ch_viewDownTarget)}
               >
                  全てのチャンネルランキングを見る
               </Button>
            </Center>
            */}
            <Midasi2>チャンネル検索</Midasi2>
            <Box bg="#ddd" mx="0.75rem" p="1rem">

               <Midasi3>キーワード</Midasi3>
               <InputGroup>
                  <InputLeftElement
                     children={<AiOutlineSearch />}
                  />
                  <Input 
                     placeholder="キーワード"
                     bg="whiteAlpha.900"
                     onChange={(e) => setChannelKeyword(e.target.value)}
                     defaultValue="" 
                  />
               </InputGroup>

               <Midasi3>人柄・属性</Midasi3>
               <Box>
                  {
                  // MyStatueWord !== "" ? 
                  myStatueWords !== "" ? 
                     <RadioButtonsC4 
                        name="MyStatueWord"
                        defaultValue={radioMyStatueWordVal}
                        handleclick={setRadioMyStatueWordVal}
                        // items={MyStatueWord.datas.map( (val) => val.word)}
                        items={myStatueWords.map( (val) => val.word)}
                     />
                  :
                     <Spinner />
                  }
               </Box>

               <Midasi3>内容</Midasi3>
               <Box>
                  {
                  // MyCategoryWord !== "" ? 
                  myCategoryWords !== "" ? 
                     <RadioButtonsC4 
                        name="MyCategoryWord"
                        defaultValue={radioMyCategoryWord}
                        handleclick={setRadioMyCategoryWord}
                        // items={MyCategoryWord.datas.map( (val) => val.word)}
                        items={myCategoryWords.map( (val) => val.word)}
                     />
                  :
                     <Spinner />
                  }
               </Box>

               
               <Box>
                  <Accordion allowMultiple>
                     <AccordionItem border="none">
                        <AccordionButton p="0">
                           <Midasi3>絞り込み</Midasi3>
                           <AccordionIcon boxSize={"2rem"}/>
                        </AccordionButton>
                        <AccordionPanel p={"1rem"}>
                           <Box fontSize="1rem">再生回数</Box>
                           下限
                           {/* <CommaInput1 settaget={setch_viewDownTarget} step={1000}/> */}
                           <CommaInput2 settaget={setch_viewDownTarget} step={1000}/>
                           上限
                           <CommaInput2 settaget={setch_viewUpTarget} step={1000}/>
                           <Box fontSize="1rem" mt="0.5rem">登録者数</Box>
                           下限
                           <CommaInput2 settaget={setch_registDownTarget} step={1000}/>
                           上限
                           <CommaInput2 settaget={setch_registUpTarget} step={1000}/>
                        </AccordionPanel>
                     </AccordionItem>
                  </Accordion>
               </Box>
                  
               

               <Center>
                  <Button 
                     w="60%"
                     rounded="full"
                     as={GatsbyLink} 
                     to={"/channellist"
                        + "?keyword=" + encodeURIComponent(channelKeyword) 
                        + "&creator=" + encodeURIComponent(radioMyStatueWordVal)
                        + "&genre=" + encodeURIComponent(radioMyCategoryWord)
                        + "&vtu=" + ch_viewUpTarget
                        + "&vtd=" + ch_viewDownTarget
                        + "&rtu=" + ch_registUpTarget
                        + "&rtd=" + ch_registDownTarget
                     } 
                     m={5} 
                     colorScheme="red"
                     onClick={() =>updataRequestKeyword(channelKeyword,"channel")}
                     // onClick={() =>console.log(ch_viewDownTarget.current.value.replace(/,/g, ''))}
                     // onClick={() =>console.log(ch_viewDownTarget)}
                  >
                     チャンネル検索
                  </Button>
               </Center>

            </Box>

            <Midasi2>ビデオ検索</Midasi2>
            <Box bg="#ddd" mx="0.75rem" p="1rem">

               <Midasi3>キーワード</Midasi3>
               <InputGroup>
                  <InputLeftElement
                     children={<AiOutlineSearch />}
                  />
                  <Input 
                     placeholder="キーワード"
                     bg="whiteAlpha.900"
                     onChange={(e) => setVideoKeyword(e.target.value)}
                     defaultValue="" 
                  />
               </InputGroup>

               <Midasi3>ユーチューブカテゴリ</Midasi3>
               <Box>
                  <RadioButtonsC4 
                     name="YoutubeCategories"
                     defaultValue={radioMyVideoCategoryWord}
                     handleclick={setradioMyVideoCategoryWord}
                     items={YoutubeCategories.catname.filter(item => {
                        // item => (item.match("急上昇")) == null)}
                        // item.match(/(急上昇|予告編)/g) == null
                        return (item.match(/(急上昇|予告編)/g) == null)
                     })
                     
                  }
                  
                  />
               </Box>
               
               <Box>
                  <Accordion allowMultiple>
                     <AccordionItem border="none">
                        <AccordionButton p="0">
                           <Midasi3>絞り込み</Midasi3>
                           <AccordionIcon boxSize={"2rem"}/>
                        </AccordionButton>
                        <AccordionPanel p={"1rem"}>
                           <Box fontSize="1rem">再生回数</Box>
                           下限
                           <CommaInput2 settaget={setvi_viewDownTarget} step={1000}/>
                           
                           上限
                           <CommaInput2 settaget={setvi_viewUpTarget} step={1000}/>
                           
                           <Box fontSize="1rem" mt="0.5rem">ライク数</Box>
                           下限
                           <CommaInput2 settaget={setvi_likeDownTarget} step={100}/>
                           上限
                           <CommaInput2 settaget={setvi_likeUpTarget} step={100}/>

                           <Box fontSize="1rem" mt="0.5rem">コメント数</Box>
                           下限
                           <CommaInput2 settaget={setvi_commentDownTarget} step={100}/>
                           上限
                           <CommaInput2 settaget={setvi_commentUpTarget} step={100}/>

                        </AccordionPanel>
                     </AccordionItem>
                  </Accordion>
               </Box>

               <Center>
                  <Button 
                     w="60%"
                     rounded="full"
                     as={GatsbyLink} 
                     to={"/videolist"
                        + "?keyword=" + encodeURIComponent(videoKeyword) 
                        + "&category=" + encodeURIComponent(radioMyVideoCategoryWord)
                        + "&vtu=" + vi_viewUpTarget
                        + "&vtd=" + vi_viewDownTarget
                        + "&ltu=" + vi_likeUpTarget
                        + "&ltd=" + vi_likeDownTarget
                        + "&ctu=" + vi_commentUpTarget
                        + "&ctd=" + vi_commentDownTarget
                     }
                     m={5} 
                     colorScheme="red"
                     onClick={() =>updataRequestKeyword(videoKeyword,"video")}
                     // onClick={() =>console.log(vi_viewDownTarget)}
                  >
                     ビデオ検索
                  </Button>
               </Center>
            </Box>

            <Box bg="#fff" mt="1rem" px="1.5rem" pb="1.5rem">
            <Midasi2 pb="0">PICK UP</Midasi2>
               <Box>
               <Midasi3>ショート動画</Midasi3>
               <Button 
                  colorScheme="blue"
                  rounded="full"
                  mr="0.6rem"
                  mb="0.6rem"
                  px="1.2rem"
                  py="0.6rem"
                  fontSize="0.9rem"
                  lineHeight="0.9rem"
                  as={GatsbyLink} 
                  to={"/videolist"
                     + "?keyword=" + encodeURIComponent("shorts")}
               >
                  shorts
               </Button>
               <Midasi3>急上昇</Midasi3>
               <Button 
                  colorScheme="blue"
                  rounded="full"
                  mr="0.6rem"
                  mb="0.6rem"
                  px="1.2rem"
                  py="0.6rem"
                  fontSize="0.9rem"
                  lineHeight="0.9rem"
                  as={GatsbyLink} 
                  to={"/videolist"
                     + "?keyword="
                     + "&category=" + encodeURIComponent("急上昇")}
               >
                  急上昇
               </Button>
               {
               // SpecialWord !== "" ? 
               //    React.Children.toArray(SpecialWord.datas.map(element => {
               //       let kanrenwords = element.words.split(",")
               //       return (
               //          <>
               //          <Midasi3>{element.title}</Midasi3>
               //          {
               //          React.Children.toArray(kanrenwords.map(val=>{
               //             return (
               //                <Button 
               //                   colorScheme="twitter"
               //                   rounded="full"
               //                   mr="0.6rem"
               //                   mb="0.6rem"
               //                   px="1.2rem"
               //                   py="0.6rem"
               //                   fontSize="0.9rem"
               //                   lineHeight="0.9rem"
               //                   as={GatsbyLink} 
               //                   to={"/videolist"
               //                      + "?keyword=" + encodeURIComponent(val)
               //                      + "&category=" + encodeURIComponent("全て")}
               //                >
               //                   {val}
               //                </Button>
               //             )
               //          }))
               //          }
               //          </>
               //       )
               //    }))
               // :
               //    <Spinner />
               }
               </Box>
               <Box>
               <Midasi3>特集</Midasi3>
               <SwiperTitleList1 postList={NewFeaturePostList} to="/category/feature/1" />
               {/* <SwiperTitleList1 postList={NewNewsPostList} to="/category/news/1" /> */}
               </Box>
            </Box>

            {/* <Midasi2>最新記事</Midasi2>
            <TitleList1 postList={NewPostList} to="/all/1" /> */}
            <Midasi2>クリエイター向け記事</Midasi2>
            <TitleList1 postList={NewCreaterPostList} to="/category/creater/1" />
            <Midasi2>レポート</Midasi2>
            <TitleList1 postList={NewReportPostList} to="/category/report/1" />
            <Midasi2>ニュース</Midasi2>
            <TitleList1 postList={NewNewsPostList} to="/category/news/1" />
            {/* <Midasi2>特集</Midasi2>
            <TitleList1 postList={NewFeaturePostList} to="/category/feature/1" /> */}
            <Midasi2>お知らせ</Midasi2>
            <TitleList1 postList={NewNoticePostList} to="/category/notice/1" />

            <Midasi2>掲載リクエスト</Midasi2>
            <Box bg="#ddd" mx="0.75rem" p="1rem">
               バズクラッチに掲載したいチャンネルをリクエストできます。
               自薦・他薦は問いません。
               <Midasi3>チャンネルURL</Midasi3>
               <Box mb="0.5rem" ml="0.5rem">
               例）<br />
               https://www.youtube.com/channel/********* <br />
               https://www.youtube.com/user/******** <br />
               https://www.youtube.com/c/********* <br />
               etc.<br />
               </Box>
               <Input 
                  placeholder=""
                  bg="whiteAlpha.900"
                  onChange={(e) => setreqestform({
                     ...reqestform,
                     channelUrl:e.target.value
                  })}
                  defaultValue="" 
               />
               <Midasi3>人柄・属性選択</Midasi3>

               <Select 
                  bg="whiteAlpha.900"
                  onChange={(e) => setreqestform({
                     ...reqestform,
                     Statue:e.target.value
                  })}
               >
                  {
                  // MyStatueWord !== "" ? 
                  //    React.Children.toArray(MyStatueWord.datas.map( (val) => {
                  myStatueWords !== "" ? 
                     React.Children.toArray(myStatueWords.map( (val) => {
                        return (
                           <option value={val.word}>{val.word}</option>
                        )
                     }))
                  :
                     ""
                  }
               </Select>
               <Midasi3>内容選択</Midasi3>
               <Select 
                  bg="whiteAlpha.900"
                  onChange={(e) => setreqestform({
                     ...reqestform,
                     Category:e.target.value
                  })}
               >
                  {
                  // MyCategoryWord !== "" ? 
                  //    React.Children.toArray(MyCategoryWord.datas.map( (val) => {
                  myCategoryWords !== "" ? 
                     React.Children.toArray(myCategoryWords.map( (val) => {
                        return (
                           <option value={val.word}>{val.word}</option>
                        )
                     }))
                  :
                     ""
                  }
               </Select>
               <Center>
                  <Button 
                     onClick={() =>updataChannelRequest(reqestform)}
                     w="60%"
                     // rounded="full"
                     m={5} 
                     colorScheme="blue"
                     isDisabled = {reqestform.isDisable}
                  >
                     リクエスト
                  </Button>
               </Center>
            </Box>

            <Midasi2>ID検索</Midasi2>
            <Box bg="#ddd" mx="0.75rem" p="1rem">
               <Midasi3>チャンネルID</Midasi3>
               <Input 
                  placeholder=""
                  bg="whiteAlpha.900"
                  defaultValue="" 
                  // ref={channelIdRef}
                  onBlur={(e) => setchannelIdRef(e.target.value)}
               />
               <Center>
                  <Button 
                     w="60%"
                     rounded="full"
                     as={GatsbyLink} 
                     // to={"/"}
                     to={"/channellist"
                        + "?id=" + channelIdRef
                     }
                     // onClick={() => alert(channelIdRef.current.value)}
                     m={5} 
                     colorScheme="red"
                  >
                     チャンネル検索
                  </Button>
               </Center>
               {/* <Midasi3>ビデオID</Midasi3>
               <Input 
                  placeholder=""
                  bg="whiteAlpha.900"
                  defaultValue=""
                  // ref={videoIdRef}
                  onBlur={(e) => setvideoIdRef(e.target.value)}
               />
               <Center>
                  <Button 
                     w="60%"
                     rounded="full"
                     as={GatsbyLink} 
                     to={"/videolist"
                        + "?id=" + videoIdRef
                     } 
                     m={5} 
                     colorScheme="red"
                  >
                     ビデオ検索
                  </Button>
               </Center> */}
            </Box>
         </Layout>
      </>
   )
}

export default IndexPage

export const pageQuery = graphql`
   query {
      allWpPost(sort: {fields: date, order: DESC}) {
         nodes {
            id
            uri
            title
            categories {
               nodes {
                  slug
               }
            }
            featuredImage {
               node {
                  altText
                  localFile {
                     childImageSharp {
                        gatsbyImageData(
                           width: 300
                           webpOptions: {quality: 100}
                        )
                     }
                     publicURL
                  }
               }
            }
            tags {
               nodes {
                  name
                  link
               }
            }
            dateGmt(formatString: "yyyy年MM月DD日")
            modifiedGmt(formatString: "yyyy年MM月DD日")
         }
      }
      wp {
         myCategoryWords {
            items {
               id
               word
            }
         }
         myStatueWords {
            items {
               id
               word
            }
         }
         myFiveRank {
            items {
               key
               items {
                  channelId
                  channelTitle
                  thumbnailUrl
               }
            }
         }
      }
   }
`;